<template>
  <div>
    <modal-form
      :title="modalTitle[modalAction]"
      :fields="fields"
      :loading="submitLoading"
      :visible="modalVisible"
      @close-modal="closeModal"
      @submit-form="submitForm"
      :cancelButton="cancelButton"
      :resetForm="resetForm"
      @inputChanged="inputChanged($event, field)"
    />
    <app-header
    :routes="routes"
    :buttons="buttons"
    @header-action="headerAction($event)"
    />
    <Main>
      <a-row :gutter="15">
        <a-tabs v-model:activeKey="activeKey">
          <a-tab-pane key="details" :tab="$t('views.ticket.details')">
            <a-row :gutter="15">
              <a-col
                :xs="24"
                :md="16"
                :lg="12"
              >
                <ticket-card
                  :ticket="ticket"
                  :shipment="shipment"
                  :assignee="assignee"
                  :createdBy="createdBy"
                  :company="company"
                  :category="category"
                  :loading="loading"
                  :headless="true"
                  :view="'full'"
                  :isClient="isClient"
                  :isAgent="isAgent"
                  :isBackOffice="isBackOffice"
                />
              </a-col>
              <a-col
                :xs="24"
                :md="8"
                :lg="12"
                class="multi-card-col"
              >
                <comments-card
                  class="file-list-card"
                  :title="$t('models.ticket.attributes.comments')"
                  :canCreateComment="canCreateComment"
                  :ticket="ticket"
                  :ticketId="ticketId"
                />
              </a-col>
            </a-row>
          </a-tab-pane>
        </a-tabs>
      </a-row>
    </Main>
  </div>
</template>

<script>
import {
  defineComponent, computed, ref, reactive, watch
} from 'vue';
import {Main} from '@/components/shared/styledComponents/Main';
import TicketCard from '@/components/tickets/TicketCard';
import CommentsCard from '@/components/shared/cards/CommentsCard';
import {useStore} from 'vuex';
import {useRoute} from 'vue-router';
import {useI18n} from 'vue-i18n';
import {useAbility} from '@casl/vue';
import ModalForm from '@/components/modals/ModalForm';
import _ from 'lodash';
import {
  requiredNumber, required
} from '@/helpers/validationRules';
import {FieldStyles} from '@/components/shared/fields/styles';
import {ticketUpdate} from '@/helpers/ticketUpdate';

export default defineComponent({
  name: 'TicketDetails',
  components: {
    Main, TicketCard, CommentsCard, ModalForm
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const {t} = useI18n();
    const {can} = useAbility();
    const ticket = computed(() => store.getters.ticket);
    const assigneeExist = computed(() => {
      return !ticket.value._jv ||
      (ticket.value._jv && Object.keys(ticket.value._jv?.relationships?.assignee).length > 1);
    });

    const buttons = computed(() => {
      const allButtons = [
        {
          action: 'startProgressTicket',
          icon: 'fast-forward',
          label: 'views.ticket.startProgress',
          visible: isBackOffice.value && (ticket.value.status === 'open' || ticket.value.status === 'reopened') && can('updateStatus', ticket.value)
        },
        {
          action: 'resolveTicket',
          icon: 'check',
          label: 'views.ticket.resolve',
          visible: isBackOffice.value && ticket.value.status === 'in_progress' && can('updateStatus', ticket.value)
        },
        {
          action: 'reopenTicket',
          icon: 'repeat',
          label: 'views.ticket.reopen',
          visible: !isBackOffice.value && ticket.value.status === 'resolved' && can('updateStatus', ticket.value)
        },
        {
          action: 'assignTicket',
          icon: 'user',
          label: 'views.ticket.assign',
          visible: isBackOffice.value && !assigneeExist.value,
          loading: loading.value
        }
      ];
      return allButtons.filter((button) => button.visible);
    });

    const isClient = computed(() => store.state.session.userType === 'ClientUser');
    const isAgent = computed(() => store.state.session.userType === 'AgentUser');
    const isBackOffice = computed(() => store.state.session.userType === 'BackOfficeUser');

    const ticketId = computed(() => route.params.ticketId);
    const activeKey = ref('details');

    const ticketsRouteName = () => {
      if (isClient.value) return 'ClientTickets';
      if (isAgent.value) return 'AgentTickets';
      if (isBackOffice.value) return 'BackOfficeTickets';
    };
    const ticketsDetailsRouteName = () => {
      if (isClient.value) return 'ClientTicketDetails';
      if (isAgent.value) return 'AgentTicketDetails';
      if (isBackOffice.value) return 'BAckOfficeTicketDetails';
    };

    const routes = computed(() => {
      return [
        {
          name: ticketsRouteName(),
          breadcrumbName: 'layout.ticket.index'
        },
        {
          name: ticketsDetailsRouteName(),
          params: {ticketId: ticketId.value},
          breadcrumbName: 'views.ticket.details'
        }
      ];
    });

    const visible = ref(false);
    const fields = reactive({
      assigneeId: {
        type: 'select',
        label: 'models.ticket.attributes.assignee',
        fieldValue: '',
        placeholder: 'models.ticket.attributes.assigneePlaceholder',
        rules: [requiredNumber],
        enableSearch: true,
        loading: false,
        options: computed(() => {
          return _.map(store.getters.users.data, (user) => {
            return {
              label: user.firstName + ' ' + user.lastName, value: parseInt(user._jv.id)
            };
          });
        }),
        trackable: true,
        hidden: computed(() => modalAction.value !== 'assignTicket'),
        styles: new FieldStyles({md: 18})
      },
      comment: {
        type: 'textArea',
        label: 'models.ticket.attributes.comment',
        fieldValue: '',
        placeholder: 'models.ticket.attributes.commentPlaceholder',
        rules: computed(() => (ticket.value.status === 'open' || ticket.value.status === 'reopened') ? [] : [required]),
        trackable: true,
        styles: new FieldStyles({md: 18}),
        hidden: computed(() => modalAction.value === 'assignTicket')
      }
    });

    const modalVisible = ref(false);
    const cancelButton = {type: 'secondary', visible: true};
    const openModal = async () => {
      modalVisible.value = true;
      if (modalAction.value === 'assignTicket') {
        assigneeFiltersState.search = undefined;
        await getUsers();
      }
    };
    const closeModal = () => {
      modalVisible.value = false;
    };
    const modalAction = ref();
    const modalTitle = {
      assignTicket: t('models.ticket.attributes.assignee'),
      startProgressTicket: t('views.ticket.startProgress'),
      resolveTicket: t('views.ticket.resolve'),
      reopenTicket: t('views.ticket.reopen')
    };
    const headerAction = (action) => {
      modalAction.value = action;
      openModal();
    };

    const {
      assigneeFiltersState,
      getUsers, inputChanged,
      submitLoading, resetForm, submitComment
    } = ticketUpdate(fields);

    const submitAssignee = async (data) => {
      resetForm.value = false;
      submitLoading.value = true;
      const formData = new FormData();
      _.forEach(data, (value, key) => {
        formData.append(key, value || '');
      });
      await store.dispatch('updateTicket', {formData, ticketId: ticketId.value});
      submitLoading.value = false;
      resetForm.value = true;
    };
    const submitForm = async (data) => {
      if (modalAction.value === 'assignTicket') {
        submitAssignee(data);
      } else {
        submitComment({
          id: ticketId.value,
          event: data.comment,
          status: ticket.value.status
        });
      }
      closeModal();
    };

    const canCreateComment = computed(() =>
      store.state.session.currentUser._jv.id === ticket.value?._jv?.relationships?.assignee?._jv?.id ||
      store.state.session.currentUser._jv.id === ticket.value?._jv?.relationships?.createdBy?._jv?.id);
    const loading = ref(false);
    const getTicket = async () => {
      if (ticketId.value) {
        loading.value = true;
        await store.dispatch('getTicket', ticketId.value);
        loading.value = false;
      }
    };
    watch(() => route.params, async () => {
      if (route.name === 'AgentTicketDetails' || route.name === 'ClientTicketDetails' || route.name === 'BackOfficeTicketDetails') {
        await getTicket();
      }
    }, {immediate: true, deep: true});

    const shipment = computed(() => store.getters.ticket?._jv?.relationships?.shipment);
    const assignee = computed(() => store.getters.ticket?._jv?.relationships?.assignee);
    const createdBy = computed(() => store.getters.ticket?._jv?.relationships?.createdBy);
    const company = computed(() => store.getters.ticket?._jv?.relationships?.company);
    const category = computed(() => store.getters.ticket?.category);
    const comments = computed(() => store.getters.comments);

    return {
      routes,
      isClient,
      isAgent,
      isBackOffice,
      visible,
      fields,
      resetForm,
      can,
      activeKey,
      ticket,
      ticketId,
      shipment,
      assignee,
      createdBy,
      company,
      category,
      comments,
      canCreateComment,
      loading,
      buttons,
      headerAction,
      modalVisible,
      submitLoading,
      closeModal,
      cancelButton,
      submitForm,
      inputChanged,
      modalAction,
      modalTitle
    };
  }
});
</script>

<style lang="scss" scoped>
.hutSpt {
  height: 100% !important;
  padding-bottom: 25px !important;
}

.multi-card-col {
  display: flex !important;
  flex-flow: column !important;
}

.file-list-card.file-list-card {
  flex-grow: 1 !important;
  margin-bottom: 25px !important;
}

.ant-card.eHvyVL {
  height: 100% !important;
}
.ant-tabs {
  width: 100%
}
</style>
