import Styled from 'vue3-styled-components';

const DetailsWrapper = Styled.div`
    .about-project-wrapper{
        height: 100%;
        background: #fff;
        border-radius: 10px;
    }

    .about-project{
        margin: 42px -40px 0;
        align-items: center;
        @media only screen and (max-width: 991px) {
            flex-flow: column;
            align-items: flex-start;
        }
        div{
            margin: 0 40px;
            span{
                color: ${({theme}) => theme['gray-solid']};
                font-size: 13px;
                display: block;
                margin-bottom: 3px;
            }
            p{
                font-weight: 500;
            }
        }
    }
`;

export {
  DetailsWrapper
};
