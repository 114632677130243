<template>
  <sdCards :headless="title ? false : true" :title="title">
    <div class="comments-container">
      <perfect-scrollbar
        :options="{
          wheelSpeed: 1,
          swipeEasing: true,
          suppressScrollX: true,
          wheelPropagation: false,
        }"
        ref="scroll"
      >
        <a-list v-if="comments?.data?.length" :data-source="comments.data" item-layout="horizontal">
          <template #renderItem="{ item }">
            <a-list-item>
              <a-row>
                <a-col :xs="24">
                  <a-comment
                    :author="item.user.name"
                    :content="item.content"
                    :datetime="moment(item.createdAt).format('DD MMM YYYY')"
                  />
                </a-col>
                <span v-if="item.status" :class="`status-text ${requestStatus(item)}`" class="status-position">
                  {{ $t(`models.ticket.attributes.statuses.${item.status}`) }}
                </span>
              </a-row>
            </a-list-item>
          </template>
        </a-list>
        <vue-eternal-loading :load="load" :is-initial="true">
          <template #loading>
            <div class="text-center">
              <a-spin />
            </div>
          </template>
          <template #no-more>
            <div />
          </template>
          <template #no-results>
            <div />
          </template>
        </vue-eternal-loading>
      </perfect-scrollbar>
      <a-comment>
        <template #content>
          <a-form-item>
            <a-textarea :rows="4" v-model:value="comment" :disabled="!$can('createComment', ticket)" />
          </a-form-item>
          <a-form-item>
            <a-button
              html-type="submit"
              :loading="loading"
              type="primary"
              @click="handleSubmit"
              :disabled="!$can('createComment', ticket) || loading"
            >
              {{ $t('actions.addComment') }}
            </a-button>
          </a-form-item>
        </template>
      </a-comment>
    </div>
    <template #button>
      <slot name="buttons"></slot>
    </template>
  </sdCards>
</template>

<script>
import {
  defineComponent, ref, computed, onMounted
} from 'vue';
import VueTypes from 'vue-types';
import moment from 'moment';
import {ticketStatuses} from '@/constants';
import {PerfectScrollbar} from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';
import {useStore} from 'vuex';

export default defineComponent({
  name: 'CommentsCard',
  components: {
    PerfectScrollbar
  },
  props: {
    title: VueTypes.string.def(''),
    ticket: VueTypes.object.def({}),
    ticketId: VueTypes.string.def('')
  },
  setup(props, context) {
    const {getters, dispatch} = useStore();
    const loading = ref(false);
    const comment = ref('');
    const scroll = ref();

    const handleSubmit = async () => {
      if (!comment.value) {
        return;
      }
      loading.value = true;
      const formData = new FormData();
      formData.append('content', comment.value);
      await dispatch('createComment', {
        ticketId: props.ticketId,
        formData
      });
      comment.value = '';
      loading.value = false;
      scroll.value.$el.scrollTop = 0;
    };
    const requestStatus = (record) => (record.status === ticketStatuses.RESOLVED ? 'success' : 'secondary');
    const comments = computed(() => getters.comments);
    const load = function({loaded}) {
      dispatch('getComments', {ticketId: props.ticketId}).then(() => {
        return loaded(comments.value.totalPages - comments.value.page);
      });
    };
    onMounted(() => {
      dispatch('reloadComments');
    });
    return {
      moment,
      loading,
      comment,
      comments,
      handleSubmit,
      requestStatus,
      load,
      scroll
    };
  }
});
</script>

<style lang="scss" scoped>
.ant-card {
  height: 100% !important;
}
.ant-col {
  width: 100% !important;
}
.ps {
  max-height: 300px;
  min-height: 0px;
  height: 100% !important;
}
</style>
<style lang="scss">
.comments-container {
  .ant-col {
    position: relative;
    width: 100% !important;
    min-height: 1px;
  }
  .status-position {
    position: absolute;
    right: 0;
  }
  span.status-text {
    font-size: 12px;
    padding: 0 12.41px;
    line-height: 1.9;
    font-weight: 500;
    border-radius: 12px;
    text-transform: capitalize;
    display: inline-block !important;
    background: rgba(95,99,242,0.1);
    &.info {
      background-color: #2c99ff15;
      color: #2c99ff;
    }
    &.success {
      background-color: #64C29515;
      color: #64C295;
    }
    &.secondary {
      background-color: #25377D15;
      color: #25377D;
    }
  }
}
</style>
