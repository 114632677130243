<template>
  <DetailsWrapper>
    <div class="ticket-summary">
      <sdCards :headless="headless"
               :title="headless ? undefined : $t('views.ticket.details')">
        <template #button>
          <sdButton shape="circle" class="btn-icon" type="default">
            <sdFeatherIcons type="edit" size="16" />
          </sdButton>
        </template>
        <a-row class="mt-0 mb-0" :justify="loading? 'center' : 'start'" :align="loading ? 'middle' : 'top'"
        :class="loading? '': 'about-project'" :gutter="12">
          <template v-if="loading">
            <a-col :xs="12" align="middle">
              <a-spin />
            </a-col>
          </template>
          <template v-else>
            <a-col v-for="(field, index) in visibleFields" :key="index"
              :xs="24"
              :sm="field.key1 === 'description' || field.key1 === 'title'? 24 : 12"
              :md="field.key1 === 'description' || field.key1 === 'title'? 24 : 9"
              :lg="field.key1 === 'description' || field.key1 === 'title'? 22 : 6"
              :xl="field.key1 === 'description' || field.key1 === 'title'? 22 : 5"
              :xxl="field.key1 === 'description' || field.key1 === 'title'? 22 : 4"
            >
              <span>{{$t(`models.ticket.attributes.${field.title}`)}}</span>
              <p v-if="field.type === 'date'" class="text-break">
                {{ moment(data[field.accessor][field.key1]).format('DD MMM YYYY') }}
              </p>
              <p v-else-if="field.type === 'name'" class="text-break">
                {{ data[field.accessor][field.key1] ?
                data[field.accessor][field.key1] + ' ' + data[field.accessor][field.key2] : ''}}
              </p>
              <span v-else-if="field.type === 'status'"
                :class="
                `status-text ${data[field.accessor][field.key1] ? requestStatus(data[field.accessor][field.key1]): ''}`"
                >
                {{ $t(`models.ticket.attributes.statuses.${data[field.accessor][field.key1]}`) }}
              </span>
              <router-link v-else-if="field.type === 'link'"
                 :to="fieldRoute(field.accessor)">
                 {{data[field.accessor][field.key1]}}
              </router-link>
              <span v-else-if="field.type === 'file'">
                <span v-for="(attachment, index) in data[field.accessor][field.key1]" :key="index">
                  <sd-button
                    class="download-attachment-btn"
                    type="default"
                    shape="circle"
                    @click="download(attachment)">
                    <sd-featherIcons class="download-text-icon" type="download" size="16"/>
                    <span class="download-text-btn">{{attachment.filename}}</span>
                  </sd-button>
                </span>
              </span>
              <p v-else class="text-break">
                {{
                  data[field.accessor][field.key1] ? data[field.accessor][field.key1] : ''
                }}
              </p>
            </a-col>
          </template>
        </a-row>
      </sdCards>
    </div>
  </DetailsWrapper>
</template>

<script>
import {
  defineComponent, computed
} from 'vue';
import VueTypes from 'vue-types';
import {DetailsWrapper} from '@/components/shared/styledComponents/DetailsWrapper';
import moment from 'moment';
import {downloadFile} from '@/helpers/downloadFile';
import {ticketStatuses} from '../../constants';

export default defineComponent({
  name: 'TicketCard',
  components: {DetailsWrapper},
  props: {
    ticket: VueTypes.object.def({}),
    shipment: VueTypes.object.def({}),
    createdBy: VueTypes.object.def({}),
    assignee: VueTypes.object.def({}),
    company: VueTypes.object.def({}),
    category: VueTypes.object.def({}),
    loading: VueTypes.bool.def(false),
    headless: VueTypes.bool.def(false),
    view: VueTypes.string.def(''),
    isClient: VueTypes.bool.def(false),
    isAgent: VueTypes.bool.def(false),
    isBackOffice: VueTypes.bool.def(false)
  },
  setup(props) {
    const fields = computed(() => [
      {
        accessor: 'ticket', title: 'identifier', key1: 'identifier'
      },
      {
        accessor: 'createdBy', title: 'reporter', key1: 'firstName', key2: 'lastName', type: 'name'
      },
      {
        accessor: 'company', title: 'company', key1: 'name', type: props.isBackOffice ? 'link' : 'text'
      },
      {
        accessor: 'assignee', title: 'assignee', key1: 'firstName', key2: 'lastName', type: 'name', hidden: !props.assignee?._jv?.id
      },
      {
        accessor: 'category', title: 'category', key1: 'value', type: 'text'
      },
      {
        accessor: 'shipment', title: 'shipmentId', key1: 'identifier', type: 'link', hidden: props.category.value !== 'Shipment'
      },
      {
        accessor: 'ticket', title: 'updatedDate', key1: 'updatedAt', type: 'date'
      },
      {
        accessor: 'ticket', title: 'status', key1: 'status', type: 'status'
      },
      {
        accessor: 'ticket', title: 'title', key1: 'title', type: 'text'
      },
      {
        accessor: 'ticket', title: 'description', key1: 'description', type: 'text'
      },
      {
        accessor: 'ticket', title: 'attachments', key1: 'attachments', type: 'file', hidden: Object.keys(props.ticket.attachments).length === 0
      }
    ]);

    const visibleFields = computed(() => fields.value.filter((field) => !field.hidden));
    const download = (attachment) => downloadFile(attachment.filename, attachment.url);
    const fieldRoute = (field) => {
      if (field === 'company') {
        return {name: 'CompanyProfile', params: {companyId: props.company?._jv?.id}};
      } else {
        let name = null;
        if (props.isClient) name = 'ClientShipmentDetails';
        else if (props.isAgent) name = 'AgentShipmentDetails';
        else name = 'BackOfficeShipmentDetails';
        let route = null;
        route = {
          name,
          params: {
            shipmentRequestId: props.shipment?.shipmentRequestId,
            shipmentId: props.shipment?.id
          }
        };
        if (props.isBackOffice) {
          route['query'] = {
            shipmentRequestId: props.shipment?.shipmentRequestId,
            currentTab: 'all'
          };
        }
        return route;
      }
    };
    const requestStatus = (status) => {
      return status === ticketStatuses.RESOLVED ? 'success' : 'secondary';
    };
    const data = computed(() => {
      return {
        ticket: props.ticket,
        shipment: props.shipment,
        assignee: props.assignee,
        createdBy: props.createdBy,
        company: props.company,
        category: props.category
      };
    });

    return {
      visibleFields,
      moment,
      download,
      fieldRoute,
      requestStatus,
      data
    };
  }
});
</script>


<style lang="scss" scoped>
.hutSpt {
  height: 94%;
}
.ticket-summary, .ticket-summary .ant-card {
  height: 100%;
}

.text-break {
  word-break: break-word!important;
  overflow-wrap: break-word!important;
}
.download-text-btn{
  color: #1890ff!important;
  padding-left: 10px!important;
  padding-right: 10px;
  width: 150px;
  overflow: hidden!important;
  text-overflow: ellipsis;
}
.download-text-icon{
  padding-left: 10px!important;
  padding-right: 10px;
}
</style>
<style lang="scss">
.download-attachment-btn{
  .igtKCI{
    padding: 0px 0px!important;
  }
}
span.status-text{
          font-size: 12px;
          padding: 0 12.41px;
          line-height: 1.9;
          font-weight: 500;
          border-radius: 12px;
          text-transform: capitalize;
          display: inline-block !important;
          background: rgba(95,99,242,0.1);
          &.info{
            background-color: #2C99FF15!important;
            color: #2C99FF!important;
          }
          &.success{
            background-color: #64C29515!important;
            color: #64C295!important;
          }
          &.secondary{
            background-color: #25377D15!important;
            color: #25377D!important;
          }
        }
</style>

